import request from '@/utils/request'

export function search (data) {
  return request({
    url: '/mailbox/search',
    method: 'post',
    data
  })
}

export function getPage (page, noitems) {
  return request({
    url: '/mailbox/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function destroy (id) {
  return request({
    url: '/mailbox/' + id,
    method: 'delete'
  })
}
export function exportExcel (data) {
  return request({
    url: '/mailbox/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
